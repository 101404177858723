import React from 'react'
import PropTypes from 'prop-types'
/* import Img from 'gatsby-image' */
import { graphql }  from 'gatsby'
import CheckBox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button';
import MuiField from '../components/MuiField';
import { 
  styledNamePage,
  header
} from '../components/styles/InternalPage.styles'

import { ipMask, ipPipe } from './../utils/masks';

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>Ferramentas</span>
              Proxy Interno
            </H4>
            <Paragraph>O Proxy Inteno HostDime é um serviço que permite que você acesse websites hospedados na rede da HostDime através de uma rede externa. Para usar este serviço, insira abaixo o endereço do site que você deseja acessar:</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 20 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <form action='http://proxy.hostdime.com/' method='post' id='urlForm'>
                  <MuiField
                    variant='outlined'
                    label='URL'
                    placeholder='https://www.hostdime.com.br'
                    id='url'
                    name='url'
                    style={{
                      maxWidth: 328,
                      marginBottom: 24
                    }}
                  />
                  <FormControlLabel
                    control={<CheckBox for='custom-dns' color='primary' />}
                    label={`CONECTE-SE A UM ENDEREÇO\nIP ESPECÍFICO`}
                    labelPlacement='end'
                    id='custom-dns'
                    name='custom_dns'
                    style={{
                      textTransform: 'normal',
                      whiteSpace: 'pre-wrap'
                    }}
                  />
                  <MuiField
                    variant='outlined'
                    label='IP Address'
                    placeholder='198.49.64.128'
                    mask={ipMask}
                    pipe={ipPipe}
                    id='connect-host'
                    name='connect_host'
                    style={{
                      maxWidth: 328,
                      marginBottom: 24
                    }}
                  />
                  <Button
                    schema='secondary'
                    color='white'
                    variant='highEmphasis'
                    width={['100%']}
                    maxWidth={['160px']}
                    type='submit'
                    style={{
                      display: 'flex',
                      border: 'none',
                      margin: '0 auto',
                      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                    }}
                  >
                    Ver Url
                  </Button>
                </form>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nossos Serviços' />
      <PageTemplate
        title='Nossos Serviços'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
